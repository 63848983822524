import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { ProductSearchResultItem } from "@web/sherlock";
import { IRow, Table, Tooltip } from "@web/ui";
import { formatISODateDurationAsNumberOfDays, imagor } from "@web/utils";

import errorIcon from "src/components/icons/ErrorIcon.svg";
import imagePlaceholder from "src/components/icons/image_placeholder.png";
import { filterOutImagesAssets, sortImagesByPriority } from "src/utils";

type Props = {
  catalogId: string;
  productsList?: ProductSearchResultItem[];
  isLoading?: boolean;
};

const ProductsListTable: FC<Props> = ({ catalogId, isLoading, productsList }) => {
  const navigate = useNavigate();

  const handleRowClick = useCallback(
    (id?: string) => {
      navigate(`/catalogs/${catalogId}/products/${id}`);
    },
    [catalogId, navigate]
  );

  const renderCellData = (data: string | number | null | undefined) =>
    data !== undefined && data !== null ? data : <img alt="missing data" src={errorIcon} />;

  const rows = useMemo(() => {
    return productsList?.map((item: ProductSearchResultItem): IRow => {
      const { product } = item;

      const sortedImages = sortImagesByPriority(product?.assets || []);
      const imageUrl = sortedImages[0]?.url;

      const price = product?.prices?.[0];

      return {
        onClick: () => handleRowClick(product?.productId),
        data: [
          {
            type: "icon",
            data: (
              <Tooltip
                hAlignTo="start"
                vAlignTo="bottom"
                tooltipText={
                  imageUrl
                    ? "Showing image = an image was provided and is showing correctly on S2S."
                    : "Image not provided = no image was provided for this item."
                }
                className="z-30 object-cover w-7 h-7"
              >
                <img
                  alt="product image"
                  src={imageUrl ? imagor(imageUrl) : imagePlaceholder}
                  width={32}
                  height={32}
                  className="border-1 w-7 h-7 rounded-full my-1 object-cover"
                />
              </Tooltip>
            ),
          },
          {
            type: "title",
            data: renderCellData(product?.productId),
          },
          {
            type: "title",
            data: renderCellData(product?.name),
          },
          {
            type: "title",
            data: renderCellData(`${price?.price} ${price?.currency}`),
          },
          {
            type: "title",
            data: renderCellData(formatISODateDurationAsNumberOfDays(product?.leadTime as string)),
          },
          {
            type: "title",
            data: renderCellData(product?.defaultOrderQuantity?.minimumOrderQuantity),
          },
          {
            type: "title",
            data: renderCellData(product?.defaultOrderQuantity?.orderQuantityIncrement),
          },
          {
            type: "title",
            data: renderCellData(product?.displayUnit),
          },
          {
            type: "title",
            data: renderCellData(filterOutImagesAssets(product?.assets || []).length),
          },
        ],
      };
    });
  }, [productsList, handleRowClick]);

  return (
    <Table
      data-testid="productListTable"
      headings={[
        { text: "Image" },
        { text: "Supplier Art. Code" },
        { text: "Short description" },
        { text: "Price" },
        { text: "Lead time" },
        { text: "Min. Order Qty." },
        { text: "Quantity Steps" },
        { text: "Unit of measure" },
        { text: "Attachments" },
      ]}
      isLoading={isLoading}
      rows={rows}
      emptyState={{
        title: "We looked everywhere..",
        subtitle: "There aren’t any products.",
      }}
    />
  );
};

export default ProductsListTable;
