import { useAuth } from "oidc-react";
import { useMemo } from "react";

import { Avatar, Dropdown, DropdownItem, Paragraph } from "@web/ui";

export const TopBar = () => {
  const auth = useAuth();
  const dropdownItemsList: DropdownItem[] = useMemo(
    () => [
      {
        key: "name",
        renderComponent: () => (
          <Paragraph size="200" className="pl-4 my-2">
            {(auth.userData?.profile.an as string) || ""}
          </Paragraph>
        ),
      },
      {
        key: "email",
        renderComponent: () => (
          <Paragraph size="200" className="pl-4 my-2">
            {auth.userData?.profile.email || ""}
          </Paragraph>
        ),
      },
    ],
    [auth.userData?.profile.email, auth.userData?.profile.an]
  );

  return (
    <div
      className="bg-neutral_0 pr-5 border-b border-b-neutral_200 w-full z-8"
      data-testid="topbar"
    >
      <div className={`w-full items-center flex flex-row-reverse relative h-[56px] bg-neutral_0`}>
        <Dropdown hAlignTo="end" vAlignTo="bottom" items={dropdownItemsList}>
          <Avatar
            text={(auth.userData?.profile.email || "").substring(0, 1).toUpperCase()}
            data-testid="topbarAvatar"
            sizeClass="6"
            className="cursor-pointer"
          />
        </Dropdown>
      </div>
    </div>
  );
};
