import { Asset } from "@web/sherlock";

import { filterOutNonImagesAssets } from "./filterOutNonImagesAssets";

const PRIORITY_TAG = "source2sea.com/sort-priority";

export const sortImagesByPriority = (assets: Asset[]): Asset[] => {
  const images = filterOutNonImagesAssets(assets);
  const parsePriority = (asset: Asset): number => {
    const priority = asset.tags?.[PRIORITY_TAG];
    return priority ? parseInt(priority, 10) : Infinity;
  };

  return images.sort((a, b) => {
    const priorityA = parsePriority(a);
    const priorityB = parsePriority(b);

    if (priorityA === priorityB) {
      return 0;
    }
    return priorityA - priorityB;
  });
};
