import { useNavigate } from "react-router-dom";

import { RoutesConfig } from "src/config/routes";

import { BadgeLink } from "../BadgeLink";
import Logo from "../icons/s2s-logo.png";

export const Sidebar = () => {
  const navigate = useNavigate();

  return (
    <div
      className="fixed top-0 left-0 h-full bg-neutral_900 w-[300px] min-w-[270px] flex flex-col pt-5 items-center z-30"
      data-testid="sidebar"
    >
      <img
        src={Logo}
        className="w-[60%] mb-8 cursor-pointer"
        alt="Pelican icon"
        onClick={() => navigate("/")}
        data-testid="sidebarLogo"
      />
      <div className="px-4 w-full" data-testid="sidebarNavigation">
        <BadgeLink
          text="Dashboard"
          pathname={RoutesConfig.dashboard}
          search="dashboard"
          data-testid="sidebarNavigation_dashboard"
        />
        <BadgeLink
          text="Catalogs"
          pathname={RoutesConfig.catalogs}
          search="catalogs"
          data-testid="sidebarNavigation_catalogs"
        />
        {/* <SimpleBadgeLink text="My Products" pathname={RoutesConfig.products} search="products" />
      <SimpleBadgeLink text="New upload" pathname={RoutesConfig.upload} search="upload" />
      <SimpleBadgeLink
        text="Upload history"
        pathname={RoutesConfig.uploadHistory}
        search="uploadHistory"
      /> */}
      </div>
    </div>
  );
};
